.home-container {
  margin-left: 80px;
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e2e8f0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .home-container {
    margin-left: 0;  /* Remove left margin on mobile */
    margin-bottom: 60px;  /* Add bottom margin for the mobile nav */
    padding: 1rem;
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .home-container {
    margin-left: 60px;  /* Adjust for narrower sidebar in landscape */
  }
}

/* Handle tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .home-container {
    margin-left: 70px;  /* Match tablet nav width */
  }
}

.about-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  padding: 3rem;
  margin: 2rem;
  overflow: hidden;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.name-container h1 {
  font-size: 3rem;
  margin: 0 0 1rem;
  background: linear-gradient(90deg, #4f46e5, #8b5cf6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.role-badge {
  background: linear-gradient(90deg, #4f46e5, #8b5cf6);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(79, 70, 229, 0.3);
}

.personal-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  max-width: 800px;
}

.info-card {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f8f9fa;
  padding: 0.6rem 1rem;
  border-radius: 10px;
  font-size: 0.95rem;
  color: #4b5563;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.info-card:hover {
  background: #f0f5ff;
}

.info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f46e5;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.linkedin {
  background: #0077b5;
  color: white;
}

.github {
  background: #333;
  color: white;
}

.social-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.bio-container {
  max-width: 800px;
  margin: 0 auto;
}

.bio {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4b5563;
  font-weight: 300;
  margin: 0;
}

.divider {
  height: 1px;
  background: linear-gradient(90deg, rgba(79, 70, 229, 0.1), rgba(79, 70, 229, 0.5), rgba(79, 70, 229, 0.1));
  margin: 3rem 0;
  transform-origin: center;
}

.section-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2.5rem;
  color: #2d2d2d;
}

.highlight {
  position: relative;
  z-index: 1;
}

.highlight::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 5px;
  left: 0;
  background: linear-gradient(90deg, rgba(79, 70, 229, 0.2), rgba(139, 92, 246, 0.2));
  z-index: -1;
  border-radius: 4px;
}

.specialties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.specialty-card {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-top: 4px solid #4f46e5;
}

.specialty-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.05) 0%, rgba(139, 92, 246, 0.05) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.specialty-card:hover::before {
  opacity: 1;
}

.specialty-icon {
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5ff;
  border-radius: 50%;
  color: #4f46e5;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

.specialty-card:hover .specialty-icon {
  transform: scale(1.1);
  box-shadow: 0 10px 25px rgba(79, 70, 229, 0.2);
}

.specialty-card h3 {
  font-size: 1.3rem;
  margin: 0 0 1rem;
  color: #2d2d2d;
  position: relative;
  z-index: 2;
}

.specialty-card p {
  color: #4b5563;
  line-height: 1.7;
  position: relative;
  z-index: 2;
}

.hobbies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.hobby-card {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border-top: 3px solid #4f46e5;
}

.hobby-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.05) 0%, rgba(139, 92, 246, 0.05) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hobby-card:hover::before {
  opacity: 1;
}

.hobby-icon {
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5ff;
  border-radius: 50%;
  color: #4f46e5;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

.hobby-card:hover .hobby-icon {
  transform: scale(1.1);
  box-shadow: 0 10px 25px rgba(79, 70, 229, 0.2);
}

.hobby-card h3 {
  font-size: 1.1rem;
  margin: 0 0 0.8rem;
  color: #2d2d2d;
  position: relative;
  z-index: 2;
}

.hobby-card p {
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.6;
  margin: 0;
  position: relative;
  z-index: 2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .home-container {
    margin-left: 0;
    margin-bottom: 60px;
    padding: 1rem;
  }
  
  .about-card {
    padding: 1.5rem;
    margin: 0.5rem;
    width: 100%;
    max-width: none;
  }
  
  .name-container h1 {
    font-size: 2.2rem;
  }
  
  .personal-info {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.75rem;
    padding: 0 0.5rem;
  }
  
  .info-card {
    width: auto;
    min-width: 140px;
    justify-content: center;
  }
  
  .social-links {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 0 1rem;
  }
  
  .social-btn {
    flex: 1;
    max-width: 160px;
    justify-content: center;
  }

  .bio {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .specialties-grid {
    grid-template-columns: 1fr;
    padding: 0 0.5rem;
  }

  .hobbies-grid {
    grid-template-columns: 1fr;
    padding: 0 0.5rem;
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .home-container {
    margin-left: 60px;
  }
  
  .about-card {
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .profile-section {
    margin-bottom: 2rem;
  }
  
  .divider {
    margin: 2rem 0;
  }
}

/* Handle tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .home-container {
    margin-left: 70px;
  }
}
