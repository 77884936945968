.contact-container {
  margin-left: 80px;
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e2e8f0 100%);
}

.contact-content {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  padding: 3rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.contact-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-icon {
  font-size: 3rem;
  color: #4f46e5;
  margin-bottom: 1rem;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.contact-header h1 {
  font-size: 2.5rem;
  color: #2d2d2d;
  margin: 0.5rem 0;
  background: linear-gradient(90deg, #4f46e5, #8b5cf6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-intro {
  max-width: 700px;
  margin: 0 auto 3rem;
  color: #4b5563;
  font-size: 1.1rem;
  line-height: 1.6;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.contact-card {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  border-top: 4px solid #4f46e5;
}

.contact-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.05) 0%, rgba(139, 92, 246, 0.05) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.contact-card:hover::before {
  opacity: 1;
}

.contact-card-icon {
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5ff;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  color: #4f46e5;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.contact-card:hover .contact-card-icon {
  transform: scale(1.1);
  box-shadow: 0 10px 25px rgba(79, 70, 229, 0.2);
}

.linkedin {
  background: #e8f0fe;
  color: #0077b5;
}

.github {
  background: #f6f8fa;
  color: #333;
}

.contact-card h3 {
  font-size: 1.3rem;
  margin: 0 0 1rem;
  color: #2d2d2d;
  position: relative;
  z-index: 2;
}

.contact-card p {
  color: #4b5563;
  margin: 0 0 1.5rem;
  position: relative;
  z-index: 2;
}

.location-detail {
  font-size: 0.85rem;
  color: #6b7280;
  margin-top: -0.5rem;
}

.contact-button {
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, #4f46e5 0%, #8b5cf6 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.25);
  margin-top: auto;
  position: relative;
  z-index: 2;
}

.contact-button:hover {
  background: linear-gradient(135deg, #4338ca 0%, #7c3aed 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(79, 70, 229, 0.3);
}

.link-arrow {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: auto;
  color: #4f46e5;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease;
}

.contact-card:hover .link-arrow {
  transform: translateX(5px);
}

.contact-message {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 2rem;
  border-top: 1px solid #e5e7eb;
}

.contact-message h3 {
  color: #2d2d2d;
  margin-bottom: 1rem;
}

.contact-message p {
  color: #4b5563;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-container {
    margin-left: 0;
    margin-bottom: 60px;
    padding: 1rem;
  }
  
  .contact-content {
    padding: 2rem;
    width: 95%;
  }
  
  .contact-header h1 {
    font-size: 2rem;
  }
  
  .contact-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .contact-container {
    margin-left: 60px;
  }
}

/* Handle tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .contact-container {
    margin-left: 70px;
  }
}
