.experience-container {
  margin-left: 80px;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e2e8f0 100%);
}

.experience-content {
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  padding: 3rem;
  margin: 2rem;
}

.intro-section {
  text-align: center;
  margin-bottom: 3rem;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.header-icon1 {
  font-size: 1.8rem;
  color: #4f46e5;
}

.section-header h2 {
  font-size: 1.8rem;
  color: #2d2d2d;
  margin: 0;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.skill-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-left: 4px solid #4f46e5;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.skill-icon {
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5ff;
  border-radius: 12px;
  color: #4f46e5;
}

.skill-details {
  flex: 1;
}

.skill-details h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #2d2d2d;
}

.skill-level {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.skill-level.advanced {
  background-color: #dcfce7;
  color: #166534;
}

.skill-level.intermediate {
  background-color: #fef3c7;
  color: #92400e;
}

.skill-level.beginner {
  background-color: #fee2e2;
  color: #b91c1c;
}

.progress-bar {
  height: 8px;
  background-color: #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #4f46e5 0%, #8b5cf6 100%);
  border-radius: 4px;
  transition: width 1s ease-in-out;
}

.divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 3rem 0;
}

.career-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.timeline {
  border-left: 2px solid #4f46e5;
  padding-left: 2rem;
  margin-left: 1rem;
}

.timeline-item {
  position: relative;
  padding-bottom: 2rem;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -2.6rem;
  top: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-color: #4f46e5;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(79, 70, 229, 0.2);
}

.timeline-item h3 {
  color: #2d2d2d;
  margin-bottom: 0.5rem;
}

.timeline-date {
  color: #4f46e5;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.timeline-item p {
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.timeline-item ul {
  color: #4b5563;
  padding-left: 1.2rem;
}

.timeline-item li {
  margin-bottom: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .experience-container {
    margin-left: 0;
    margin-bottom: 60px;
    padding: 1rem;
  }

  .experience-content {
    padding: 1.5rem;
    margin: 0.5rem;
    width: 100%;
    max-width: none;
  }

  .intro-section {
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }

  .section-header {
    flex-direction: row;
    text-align: left;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }

  .section-header h2 {
    font-size: 1.6rem;
  }

  .skills-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 0.5rem;
  }

  .career-section {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0 0.5rem;
  }

  .timeline {
    padding-left: 1.5rem;
    margin-left: 0.5rem;
  }

  .timeline-item::before {
    left: -2.1rem;
  }

  .timeline-item h3 {
    font-size: 1.2rem;
  }

  .timeline-item p,
  .timeline-item li {
    font-size: 0.95rem;
  }

  .skill-categories {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 0.5rem;
  }

  .skill-category {
    padding: 1.2rem;
  }

  .skill-category h3 {
    font-size: 1.2rem;
  }

  .skill-list li {
    padding: 0.4rem 0;
  }

  .resume-button {
    margin: 2rem auto 0;
    display: block;
    width: calc(100% - 2rem);
    max-width: 300px;
  }
}

/* Additional responsive adjustments for very small screens */
@media (max-width: 360px) {
  .experience-content {
    padding: 1rem;
  }

  .timeline {
    padding-left: 1.2rem;
  }

  .timeline-item::before {
    left: -1.8rem;
    width: 0.8rem;
    height: 0.8rem;
  }
}

/* Handle landscape orientation on mobile */
@media (max-height: 500px) and (orientation: landscape) {
  .experience-container {
    margin-left: 60px;  /* Adjust for narrower sidebar in landscape */
  }
}

/* Handle tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .experience-container {
    margin-left: 70px;  /* Match tablet nav width */
  }
}

.resume-button {
  margin-top: 2rem;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, #4f46e5 0%, #8b5cf6 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.25);
}

.resume-button:hover {
  background: linear-gradient(135deg, #4338ca 0%, #7c3aed 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(79, 70, 229, 0.3);
}

.resume-button:active {
  transform: translateY(0);
}

.resume-button svg {
  font-size: 1.1rem;
}

.project-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  border-left: 4px solid #4f46e5;
  transition: all 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.project-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.project-title h3 {
  margin: 0;
  color: #2d2d2d;
}

.project-link {
  color: #4f46e5;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  transition: color 0.2s ease;
}

.project-link:hover {
  color: #4338ca;
}

.project-description {
  color: #4b5563;
  margin-bottom: 1rem;
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tech-tag {
  background-color: #f3f4f6;
  color: #4f46e5;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.skills-container {
  display: flex;
  gap: 3rem;
  margin-bottom: 2rem;
}

.skills-left {
  flex: 1;
}

.skills-right {
  flex: 1;
}

.skill-bar-container {
  margin-bottom: 1.5rem;
}

.skill-bar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.skill-bar-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.skill-bar-track {
  height: 8px;
  background-color: #f3f4f6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.skill-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #4f46e5 0%, #8b5cf6 100%);
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 1s ease;
}

.skill-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.skill-category {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #4f46e5;
  transition: all 0.3s ease;
}

.skill-category:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.skill-category h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.skill-category-icon {
  font-size: 1.2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5ff;
  border-radius: 8px;
  color: #4f46e5;
}

.skill-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.skill-list li {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #f3f4f6;
}

.skill-list li:last-child {
  border-bottom: none;
}

.skill-icon-small {
  width: 1.5rem;
  text-align: center;
}

.skill-highlight {
  background: linear-gradient(90deg, #4f46e5, #8b5cf6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
